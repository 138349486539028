import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import classes from "../../Step2/shortTermLosses/shortTermLosses.module.scss";
import Navigation from "../../../../components/Navigation/Navigation";
import InheritanceYou from "../../../../components/ContentRow/Step3/Inheritance/InheritanceYou";
import InheritanceSpouse from "../../../../components/ContentRow/Step3/Inheritance/InheritanceSpouse";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const Inheritance = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'Do you expect to receive an inheritance?',
    };

    const title = "Retirement planning - Will you receive an inheritance?";

    const [next, setNext] = useState(retirementCalcLinks.savingsAndInvestmentsSummary);

    const nextLink = useCallback(() => {
        if(props.you.inheritance.exist || props.spouse.inheritance.exist){
            return retirementCalcLinks.inheritanceAmount;
        }else {
            return retirementCalcLinks.savingsAndInvestmentsSummary
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink());
    }, [props, nextLink]);

    const onChange = () => {
        setNext(nextLink())
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(16/19)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                <InnerLayoutStepContent type={'you'}>
                    <div className={classes.RadioWrap}>
                        <InheritanceYou onChange={onChange}/>
                    </div>
                </InnerLayoutStepContent>

                {
                    props.isSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <div className={classes.RadioWrap}>
                            <InheritanceSpouse onChange={onChange}/>
                        </div>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inheritance);