import React, { useEffect, useState } from 'react';
import Error from "../../../Error/Error";
import classes from "../../Gender/Gender.module.scss";
import Radio from "../../../UI/Radio/Radio";
import { connect } from "react-redux";
import { setInheritanceYou } from "../../../../store/actions/savingsAndInvestments";

const InheritanceYou = ( props ) => {
    const initState = props.inheritanceExist ? 'Yes' : 'No';
    const [checked, setChecked] = useState(initState);
    const [error, setError] = useState(false);

    const radios = [
        {
            value: 'Yes'
        },
        {
            value: 'No'
        }
    ];

    const changeValHandler = (e) => {
        const val = e.target.value;
        setChecked(val);
        props.setInheritanceYou(val);
        props.onChange();
    };

    useEffect(() => {
        setError(checked === '')
    }, [props, checked]);

    return (
        <React.Fragment>
            {
                error &&
                <Error
                    message='Selection required'
                    style={{
                        left:'50%',
                        transform: 'translateX(-50%)',
                        marginLeft:0
                    }}
                />
            }

            {
                radios.map((radio, index) => {
                    return (
                        <div key={index} className = {classes.ContentRow__card__radio}>
                             <Radio
                                 changed={changeValHandler}
                                 name='inheritanceYou'
                                 value={radio.value}
                                 isSelected={ checked === radio.value }
                             />
                         </div>
                    )
                })
            }
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        inheritanceExist: state.savingsAndInvestments.you.inheritance.exist,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInheritanceYou: (val)=> dispatch(setInheritanceYou(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( InheritanceYou);